import React from 'react';

function ToolTipContent({ content }) {
  return (
    <div className="text-left bg-white text-gray-800 border border-gray-200 shadow-lg rounded-md p-4 max-w-xs">
      <h2 className="font-bold">Gesetzlicher Grundbetrag:</h2>
      <p className="mb-2">14,6% Ihres monatlichen Gehalts</p>
      <h2 className="font-bold">Zusatzbeitrag der Krankenkasse:</h2>
      <p className="mb-2">{content}% Ihres monatlichen Gehalts</p>
      <h2 className="font-bold">Arbeitsgeberanteil:</h2>
      <p>Falls Sie angestellt sind zahlt Ihr Arbeitgeber 50%</p>
    </div>
  );
}

export default ToolTipContent;