import React from "react";
import { FaInstagram, FaFacebookF } from 'react-icons/fa';
import { IoIosArrowUp } from 'react-icons/io';

const Footer = () => (
  <footer className="footer mt-auto py-0">
    <section className=" mx-auto py-8 px-4 bg-[#E6EAEC]">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <div>
          <h4 className="text-xl font-bold mb-4">Netzwerk</h4>
          <ul className="space-y-2 text-sm">
            <li className="flex items-center">
              <span className="mr-2">›</span>
              <a href="https://www.dentnet.de/netzwerk/ueber-das-dentnet" className="hover:underline">Über das DentNet</a>
            </li>
            <li className="flex items-center">
              <span className="mr-2">›</span>
              <a href="https://www.dentnet.de/netzwerk/qualitaet-im-dentnet" className="hover:underline">Qualität im DentNet</a>
            </li>
            <li className="flex items-center">
              <span className="mr-2">›</span>
              <a href="https://www.dentnet.de/karriere" className="hover:underline">Karriere im DentNet</a>
            </li>
            <li className="flex items-center">
              <span className="mr-2">›</span>
              <a href="https://dashboard.dentnet.de/" target="_blank" rel="nofollow noopener noreferrer" className="hover:underline">Zum Partnerportal</a>
            </li>
          </ul>
        </div>
        <div>
          <h4 className="text-xl font-bold mb-4">Kommunikation</h4>
          <div className="flex space-x-4 mb-4">
            <a href="https://www.instagram.com/dentnet.deutschland/" target="_blank" rel="nofollow noopener noreferrer" className="w-10 h-10 border-2 border-gray-400 text-gray-400 flex items-center justify-center rounded-full hover:border-gray-600 hover:text-gray-600">
              <FaInstagram size={20} />
            </a>
            <a href="https://www.facebook.com/DentNet-1829320350731821/" target="_blank" rel="nofollow noopener noreferrer" className="w-10 h-10 border-2 border-gray-400 text-gray-400 flex items-center justify-center rounded-full hover:border-gray-600 hover:text-gray-600">
              <FaFacebookF size={20} />
            </a>
          </div>
          <ul className="space-y-2 text-sm">
            <li className="flex items-center">
              <span className="mr-2">›</span>
              <a href="https://www.dentnet.de/dentnet/downloads" className="hover:underline">Downloads</a>
            </li>
          </ul>
        </div>
        <div>
          <h4 className="text-xl font-bold mb-4">Für zahnärztliche Praxen</h4>
          <p className="text-sm mb-4">
            Das DentNet unterstützt zahnärztliche Praxisinhaberinnen und Praxisinhaber, neue Patientenkontakte herzustellen sowie das eigene Angebot, um die nachgefragten Leistungen des DentNet zu ergänzen.
          </p>
          <a href="https://www.dentnet.de/fuer-zahnaerzte" className="bg-white text-gray-700 px-4 py-2 rounded text-sm hover:bg-gray-100 inline-block border border-gray-300">
            Mehr erfahren
          </a>
        </div>
        <div>
          <h4 className="text-xl font-bold mb-4">Ratgeber</h4>
          <ul className="space-y-2 text-sm mb-4">
            <li className="pb-2 border-b border-gray-300">
              <a href="/ratgeber/mund-und-zahngesundheit-in-den-wechseljahren" className="hover:underline">Mund- und Zahngesundheit in...</a>
            </li>
            <li className="py-2 border-b border-gray-300">
              <a href="/ratgeber/geschichte-des-zaehneputzens" className="hover:underline">Die faszinierende Geschichte...</a>
            </li>
            <li className="pt-2">
              <a href="/ratgeber/pact" className="hover:underline">Schmerzfreie Zahnbehandlung...</a>
            </li>
          </ul>
          <a href="/ratgeber" className="bg-white text-gray-700 px-4 py-2 rounded text-sm hover:bg-gray-100 inline-block border border-gray-300">
            Alle Beiträge ansehen
          </a>
        </div>
      </div>
    </section>
    <section className="py-3 bg-[#505A5F] text-white">
      <div className="container mx-auto flex justify-between items-center px-4">
        <nav>
          <ul className="flex space-x-6">
            <li><a href="https://www.dentnet.de/impressum" className="hover:underline">Impressum</a></li>
            <li><a href="https://www.dentnet.de/datenschutz" className="hover:underline">Datenschutz</a></li>
            <li><a href="https://www.dentnet.de/sitemap" className="hover:underline">Sitemap</a></li>
          </ul>
        </nav>
        <a href="#top" className="flex items-center text-sm hover:underline">
          oben <IoIosArrowUp className="ml-1" size={16} />
        </a>
      </div>
    </section>
  </footer>
);

export default Footer;