import React from "react";
import blueBackground from "../assets/success-page/Bild-20.jpg";
import ErfolgIcon from "../assets/success-page/Erfolg_Icon.png";
import PruefungIcon from "../assets/success-page/pruefung_icon.png";
import RueckfragenIcon from "../assets/success-page/rueckfragen_icon.png";
import BestaetigungIcon from "../assets/success-page/bestaetigung_icon.png";
import NeuerSchutzIcon from "../assets/success-page/neuerSchutz_icon.png";
import TeethPass from "../assets/success-page/teaser_dentnet_dentnet_pass.jpg";
import Zweitprothese from "../assets/success-page/teaser_dentnet_zweitprothese.jpg";

const SuccessPage = () => {
  return (
    <div className="font-sans bg-white min-h-screen flex flex-col">
      {/* Search bar */}
      <div className="bg-gray-100 py-3 sticky top-0 z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center bg-white rounded-md shadow-sm">
            <input
              type="text"
              placeholder="Finde deine Zahnarztpraxis in ..."
              className="w-full py-2 px-4 rounded-md focus:outline-none"
            />
          </div>
        </div>
      </div>

      {/* Main content */}
      <main className="flex-grow max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 space-y-12">
        <h1 className="text-2xl sm:text-3xl font-light text-center">
          Vielen Dank für Ihre Anfrage
        </h1>

        {/* Blue background image */}
        <img
          src={blueBackground}
          alt="Blue background"
          className="w-full object-cover h-32 sm:h-48"
        />

        {/* Success message */}
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="max-w-2xl mb-8 md:mb-0 space-y-4">
            <h2 className="text-xl sm:text-2xl font-light">
              Ihr Wechselantrag wurde erfolgreich übermittelt.
            </h2>
            <p className="text-gray-600 text-sm sm:text-base">
              Ihr Antrag zum Wechsel der Krankenkasse wurde erfolgreich
              übermittelt. Wir freuen uns, dass Sie nach dem Vergleich der
              verschiedenen Krankenkassen eine fundierte Entscheidung getroffen
              haben.
            </p>
            <button className="bg-[#0098D6] text-white px-6 py-2 rounded-md text-base sm:text-lg font-medium hover:bg-[#007bb0] transition-colors">
              Antrag als PDF downloaden
            </button>
          </div>
          {/* Updated ErfolgIcon usage */}
          <img
            src={ErfolgIcon}
            alt="Erfolg Icon"
            className="w-100 h-64 object-contain"
          />
        </div>

        {/* Next steps */}
        <h3 className="text-2xl font-light text-left mb-8">
          Wie geht es jetzt weiter?
        </h3>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 text-center bg-[#F8F8FA] py-8 px-4 mb-16">
          <div>
            <img
              src={PruefungIcon}
              alt="Prüfung Icon"
              className="w-20 h-20 mx-auto mb-4"
            />
            <h4 className="font-semibold mb-2">Prüfung Ihres Antrags</h4>
            <p className="text-sm text-gray-600">
              Ihre neue Krankenkasse prüft Ihren Wechselantrag.
            </p>
          </div>
          <div>
            <img
              src={RueckfragenIcon}
              alt="Rückfragen Icon"
              className="w-20 h-20 mx-auto mb-4"
            />
            <h4 className="font-semibold mb-2">Rückmeldung</h4>
            <p className="text-sm text-gray-600">
              Sie erhalten eine Bestätigung oder werden bei Rückfragen
              kontaktiert.
            </p>
          </div>
          <div>
            <img
              src={BestaetigungIcon}
              alt="Bestätigung Icon"
              className="w-20 h-20 mx-auto mb-4"
            />
            <h4 className="font-semibold mb-2">Wechselbestätigung</h4>
            <p className="text-sm text-gray-600">
              Nach Genehmigung erhalten Sie Ihre neue Versicherungskarte.
            </p>
          </div>
          <div>
            <img
              src={NeuerSchutzIcon}
              alt="Neuer Schutz Icon"
              className="w-20 h-20 mx-auto mb-4"
            />
            <h4 className="font-semibold mb-2">Neuer Versicherungsschutz</h4>
            <p className="text-sm text-gray-600">
              Ihr Versicherungsschutz wechselt zum angegebenen Termin.
            </p>
          </div>
        </div>

        {/* Additional interest section */}
        <h3 className="text-2xl font-light text-left mb-4 text-gray-700">
          Das könnte Sie auch noch interessieren
        </h3>
        <div className="grid grid-cols-2 gap-3">
          <div className="aspect-square">
            <img
              src={TeethPass}
              alt="Zahnersatz-Pass"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="aspect-square bg-gray-100 flex flex-col items-center justify-center p-3 text-center">
            <h4 className="text-[#00508b] font-light text-3xl mb-6 leading-tight">
              Exklusive Vorteile sichern
              <br />
              mit unserem Zahnersatz-Pass:
            </h4>
            <button className="bg-[#00508b] text-white px-8 py-3 rounded text-xl font-light hover:bg-[#0087c0] transition-colors">
              Zahnersatz-Pass
            </button>
          </div>
          <div className="aspect-square bg-gray-100 flex flex-col items-center justify-center p-3 text-center">
            <h4 className="text-[#00508b] font-light text-3xl mb-6 leading-tight">
              Hochwertige Zweitprothese kostenlos
              <br />
              zum neuen Zahnersatz erhalten:
            </h4>
            <button className="bg-[#00508b] text-white px-8 py-3 rounded text-xl font-light hover:bg-[#0087c0] transition-colors">
              Zweitprothese
            </button>
          </div>
          <div className="aspect-square">
            <img
              src={Zweitprothese}
              alt="Zweitprothese"
              className="w-full h-full object-cover"
            />
          </div>
        </div>

        {/* Contact section */}
        <div className="bg-yellow-400 text-center py-6 sm:py-8 rounded-lg shadow-md">
          <p className="text-lg sm:text-xl font-semibold mb-2">
            Unter 0800 - 46393360
          </p>
          <p className="mb-4">informieren wir Sie auch persönlich</p>
          <button className="bg-white text-yellow-600 px-6 py-2 rounded-md font-semibold hover:bg-gray-100 transition-colors">
            Kostenlos anrufen
          </button>
        </div>
      </main>
    </div>
  );
};

export default SuccessPage;
