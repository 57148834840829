import React, { useState } from "react";
import FilterCategory from "./FilterCategory";
import { FaUser, FaChevronDown, FaChevronUp, FaFilter } from "react-icons/fa";


const FilterSection = ({
  onFilterChange,
  activeFilters,
  allInsurances,
  setIsCurrentInsuranceVisible,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const groupedQuestions = allInsurances.reduce((acc, insurance) => {
    insurance.sections.forEach((section) => {
      if (!acc[section.title]) {
        acc[section.title] = new Set();
      }
      section.questions.forEach((question) => {
        acc[section.title].add(question.id.split("_")[0]);
      });
    });
    return acc;
  }, {});

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className="relative lg:static lg:w-1/3 ">
      <div
        className={`${
          isOpen ? "absolute inset-0" : "relative"
        } w-full lg:relative lg:bg-[#F5F9FC] z-50`}
        style={{
          height: isOpen ? "100vh" : "auto",
          backgroundColor: isOpen ? "white" : undefined,
        }}
      >
        <button
          className="w-full py-3 px-4 flex justify-between items-center text-left lg:hidden bg-gray-200"
          onClick={toggleOpen}
        >
          <div className="flex items-center">
            <FaFilter className="text-gray-600 mr-2" size={20} />
            <span className="text-gray-700 font-semibold">Ihre Auswahl</span>
          </div>
          {isOpen ? (
            <FaChevronUp className="text-gray-600" />
          ) : (
            <FaChevronDown className="text-gray-600" />
          )}
        </button>
        <div
          className={`${
            isOpen ? "block" : "hidden"
          } lg:block p-4 lg:p-6  bg-[#F5F9FC]`}
        >
          {Object.entries(groupedQuestions).map(
            ([sectionTitle, questionTypes]) => (
              <FilterCategory
                key={sectionTitle}
                sectionTitle={sectionTitle}
                questionTypes={Array.from(questionTypes)}
                onFilterChange={onFilterChange}
                activeFilters={activeFilters}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterSection;
