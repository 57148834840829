import React, { useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { RiCheckboxCircleLine } from "react-icons/ri";
import { Tooltip } from "react-tooltip";
import { getQuestionTypeName } from "../utils/questionTypeTranslator";
import ToolTipContentServices from "./ui/toolTipContentServices";
import { extractServiceNumbers } from "../utils/extractServiceValues";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import ToolTipContent from "./ui/toolTipContent";


const InsuranceCard = ({
  insurance,
  isCurrentInsurance,
  currentInsuranceName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  const getSectionTitle = (title) => {
    const titleMap = {
      teeth: "Zähne",
      vaccine: "Impfungen",
      sports: "Sport & Bewegung",
      medicine: "Medizin",
      cancer: "Krebsvorsorge",
      artificial: "Künstliche Befruchtung",
      service: "Service",
    };
    return titleMap[title] || title.charAt(0).toUpperCase() + title.slice(1);
  };

  const getSectionRating = (questions) => {
    const totalQuestions = questions.length;
    const availableQuestions = questions.filter(
      (q) => q.value === "yes"
    ).length;
    const missingQuestions = totalQuestions - availableQuestions;

    if (totalQuestions === 0) return "";
    if (missingQuestions === 0) return "sehr gut";
    if (missingQuestions === 1 && totalQuestions > 1) return "gut";
    return "";
  };

  function nextToRegPage() {
    console.log("Proceed to the next page...");
    const searchParams = new URLSearchParams(window.location.search);
    const data = searchParams.get("data");
    const jData = JSON.parse(atob(data));
    const insuranceData = {
      ...jData,
      currentInsuranceName: currentInsuranceName,
      selectedInsurance: {
        companyName: insurance.companyName,
        _id: insurance._id,
        userId: insurance.userId,
        monthlyCost: insurance.monthlyCost,
        savings: insurance.savings,
        annualCost: insurance.annualCost,
        logo: insurance.logo,
      },
    };
    const encodedData = btoa(JSON.stringify(insuranceData));
    window.location.href = `/new-application/${insurance._id}/${jData.employmentStatus}?data=${encodedData}`;
  }

  return (
    <div className={`border border-gray-200 ${
      isCurrentInsurance ? "bg-[#F5F9FC]" : "bg-white"
    } mb-6 relative overflow-visible`}>
      {isCurrentInsurance && (
        <div className="absolute top-0 right-0 md:right-5 bg-[#00508b] text-white px-2 md:px-4 py-1 md:py-2 text-xs md:text-sm font-semibold z-[1]">
          Ihre Krankenkasse
        </div>
      )}
      <div className="p-4 md:p-10">
        <div className="flex flex-col md:flex-row items-start">
          {/* Mobile-specific top section */}
          <div className="flex justify-between items-start w-full md:hidden mb-4">
            <div className="w-1/2 pr-2">
              <div className="w-full h-16 flex items-center justify-start">
                {insurance.logo ? (
                  <img
                    src={`${process.env.REACT_APP_API_BASEURL.replace(
                      "/api",
                      ""
                    )}${insurance.logo}`}
                    alt={`${insurance.companyName} logo`}
                    className="max-h-full max-w-full object-contain"
                    onError={(e) => {
                      console.error("Error loading image:", e);
                      e.target.onerror = null;
                      e.target.src = "/path/to/fallback/image.png";
                    }}
                  />
                ) : (
                  <div className="text-lg font-semibold text-center text-gray-700">
                    {insurance.companyName}
                  </div>
                )}
              </div>
            </div>
            <div className="w-1/2 text-right pt-4">
              <p className="text-2xl font-bold">
                {insurance.monthlyCost.toString().replace(".", ",")} €
              </p>
              <p className="text-xs text-gray-500 flex items-center justify-end">
                monatlich
                <span
                  data-tooltip-id={`tooltip-price-explanation-${insurance._id}`}
                  data-tooltip-content={insurance.additionalContribution}
                  className="cursor-help inline-block ml-1 w-4 h-4 text-xs bg-gray-300 rounded-full text-center text-gray-600 relative z-20"
                >
                  ?
                </span>
                <Tooltip
                            id={`tooltip-price-explanation-${insurance._id}`}
                            place="top"
                            effect="solid"
                            className="z-100"
                            style={{
                              backgroundColor: "transparent",
                              padding: 0,
                            }}
                            render={({ content }) => (
                              <ToolTipContent content={content} />
                            )}
                          />
              </p>
              {!isCurrentInsurance && parseFloat(insurance.savings) !== 0 && (
                <p
                  className={`text-sm font-bold ${
                    parseFloat(insurance.savings) > 0
                      ? "text-[#44AC34]"
                      : "text-red-500"
                  } mt-1`}
                >
                  {parseFloat(insurance.savings) > 0
                    ? `${insurance.savings
                        .toString()
                        .replace(".", ",")} € im Jahr sparen`
                    : `${Math.abs(parseFloat(insurance.savings))
                        .toString()
                        .replace(".", ",")} € Mehrkosten pro Jahr`}
                </p>
              )}
            </div>
          </div>

          {/* Desktop-specific logo section */}
          <div className="hidden md:block md:w-1/4 pr-4 mb-0">
            <div className="w-full h-24 flex flex-col items-center justify-center">
              {insurance.logo ? (
                <img
                  src={`${process.env.REACT_APP_API_BASEURL.replace(
                    "/api",
                    ""
                  )}${insurance.logo}`}
                  alt={`${insurance.companyName} logo`}
                  className="max-h-full max-w-full object-contain"
                  onError={(e) => {
                    console.error("Error loading image:", e);
                    e.target.onerror = null;
                    e.target.src = "/path/to/fallback/image.png";
                  }}
                />
              ) : (
                <div className="text-lg font-semibold text-center text-gray-700">
                  {insurance.companyName}
                </div>
              )}
            </div>
          </div>

          <div className="w-full md:w-1/2 px-0 md:px-4 mb-4 md:mb-0">
            <p className="text-sm md:text-base text-gray-600 mb-2 font-bold">
              Beitragssatz:{" "}
              {(insurance.additionalContribution + 14.6)
                .toString()
                .replace(".", ",")}
              %
            </p>
            <div className="grid grid-cols-1 md:block gap-1">
              {insurance.sections &&
                insurance.sections.map((section, index) => (
                  <p
                    key={index}
                    className="flex items-center text-xs md:text-sm mb-1"
                    style={{
                      display:
                        getSectionRating(section.questions) === "" && "none",
                    }}
                  >
                    <span className="text-[#44AC34] mr-1 md:mr-2 flex-shrink-0">
                      <RiCheckboxCircleLine size={16} />
                    </span>
                    <span className="font-semibold mr-1 whitespace-nowrap">
                      {getSectionTitle(section.title)}:
                    </span>
                    <span className="text-gray-700 whitespace-nowrap">
                      {getSectionRating(section.questions)}
                    </span>
                  </p>
                ))}
            </div>
          </div>

          {/* Desktop-specific price section */}
          <div className="hidden md:block md:w-1/4 text-right">
            <p className="text-2xl font-bold">
              {insurance.monthlyCost.toString().replace(".", ",")} €
            </p>
            <p className="text-sm text-gray-500">
              monatlich
              <span
                data-tooltip-id={`tooltip-price-explanation-${insurance._id}`}
                data-tooltip-content={insurance.additionalContribution}
                className="cursor-help inline-block ml-1 w-4 h-4 text-xs bg-gray-300 rounded-full text-center text-gray-600 relative z-20"
              >
                ?
              </span>
              <Tooltip
                            id={`tooltip-price-explanation-${insurance._id}`}
                            place="top"
                            effect="solid"
                            className="z-100"
                            style={{
                              backgroundColor: "transparent",
                              padding: 0,
                            }}
                            render={({ content }) => (
                              <ToolTipContent content={content} />
                            )}
                          />
            </p>
            {!isCurrentInsurance && parseFloat(insurance.savings) !== 0 && (
              <p
                className={`text-sm font-bold ${
                  parseFloat(insurance.savings) > 0
                    ? "text-[#44AC34]"
                    : "text-red-500"
                } mt-1`}
              >
                {parseFloat(insurance.savings) > 0
                  ? `${insurance.savings
                      .toString()
                      .replace(".", ",")} € im Jahr sparen`
                  : `${Math.abs(parseFloat(insurance.savings))
                      .toString()
                      .replace(".", ",")} € Mehrkosten pro Jahr`}
              </p>
            )}
          </div>
        </div>

        <div className="flex justify-between items-center mt-4 pt-4 border-t border-gray-200">
          <button
            className="text-sm text-gray-600 hover:text-blue-800 focus:outline-none flex items-center"
            onClick={toggleOpen}
          >
            Detaillierte Leistungsübersicht{" "}
            {isOpen ? (
              <MdKeyboardArrowUp size={20} className="ml-1" />
            ) : (
              <MdKeyboardArrowDown size={20} className="ml-1" />
            )}
          </button>
          {!isCurrentInsurance && (
            <button
              onClick={nextToRegPage}
              className="bg-[#0098D6] hover:bg-[#0088C6] text-white px-4 py-2 rounded text-sm"
            >
              weiter
            </button>
          )}
        </div>
      </div>
      {isOpen && (
        <div className="border-t border-gray-200 p-4 md:p-6">
          {insurance.sections &&
            insurance.sections.map((section, index) => (
              <div key={index} className="mb-6">
                <div className="flex items-center bg-gray-100 p-3 rounded-t-lg">
                  <h3 className="font-semibold text-gray-700 w-1/3 text-sm sm:text-base">
                    {getSectionTitle(section.title)}
                  </h3>
                  <span className="text-[#44AC34] w-1/3 text-center text-sm sm:text-base">
                    {getSectionRating(section.questions)}
                  </span>
                  <div className="w-1/3"></div>
                </div>
                <div className="bg-white rounded-b-lg shadow-sm">
                  {section.questions.map((question, qIndex) => (
                    <div
                      key={qIndex}
                      className="flex items-start py-3 px-4 border-b border-gray-100 last:border-b-0"
                      style={{ display: question.value === "no" ? "none" : "flex" }}
                    >
                      <span className="text-gray-700 text-xs sm:text-base w-1/3 pr-2">
                        {getQuestionTypeName(question.id.split("_")[0])}
                      </span>
                      <div className="flex items-start w-2/3">
                        <div className="flex-shrink-0 w-5 mr-2 sm:ml-[20%] ml-[14%]">
                          {question.value === "yes" ? (
                            <FaCheck className="text-[#44AC34]" />
                          ) : (
                            <FaTimes className="text-red-500" />
                          )}
                        </div>
                        <div className="flex flex-grow items-center justify-between">
                          <span className="text-gray-600 text-xs sm:text-base">
                            {question.value === "yes"
                              ? extractServiceNumbers(section, question.id.split("_")[0])
                              : "Nicht übernommen"}
                          </span>
                          <div className="flex justify-end items-center ml-2">
                            <span
                              data-tooltip-id={`tooltip-service-${question.id.split("_")[0]}`}
                              data-tooltip-content={question.id.split("_")[0]}
                              className="cursor-help inline-flex items-center justify-center w-4 h-4 text-xs bg-gray-300 rounded-full text-gray-600 flex-shrink-0"
                            >
                              ?
                            </span>
                            <Tooltip
                              id={`tooltip-service-${question.id.split("_")[0]}`}
                              place="top"
                              effect="solid"
                              className="z-100"
                              style={{
                                backgroundColor: "transparent",
                                padding: 0,
                              }}
                              render={({ content }) => (
                                <ToolTipContentServices content={content} />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default InsuranceCard;