import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ComparisonPage from "./pages/ComparisonPage";
import ServicePage from "./pages/ServicePage";
import FormPage from "./pages/PreinformationPage";
import Header from "../src/pages/layouts/Header";
import Footer from "../src/pages/layouts/Footer";
import "./App.css";
import ApplicationPage from "./pages/ApplicationPage";
import SuccessPage from "./pages/SuccessPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App: React.FC = () => {
  return (
    <>
      <ToastContainer />
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<FormPage />} />
          <Route path="/comparison-platform" element={<ComparisonPage />} />
          <Route path="/services" element={<ServicePage />} />

          <Route
            path="/new-application/:insuranceId/:employmentStatus"
            element={<ApplicationPage />}
          />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default App;
